import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import List from '../../components/List'
import LayoutListView from '../../components/LayoutListView'
import { sortBySortOrder } from '../../shared/utilities'

const MemberBenefits = ({ location }) => {
  const configID = '51kN87d1gLOkNFso96Q75O'
  const queryData = useStaticQuery(graphql`
    {
      allContentfulOurTeam(
        sort: { fields: sortOrder, order: ASC }
        filter: { team: { eq: "Staff" } }
      ) {
        edges {
          node {
            id
            profileName
            profileTitle
            profileEmail
            sortOrder
            profilePhoto {
              title
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const data = queryData.allContentfulOurTeam.edges
    .map(obj => ({
      id: obj.node.id,
      name: obj.node.profileName,
      title: obj.node.profileTitle,
      email: obj.node.profileEmail,
      organisation: null,
      sortOrder: obj.node.sortOrder,
      photo: obj.node.profilePhoto
    }))
    .sort(sortBySortOrder)

  return (
    <LayoutListView path={location.pathname} configID={configID}>
      <List data={data} itemType="team" className="items-container" />
    </LayoutListView>
  )
}

export default MemberBenefits

MemberBenefits.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
